.wrapper{
    perspective: 1000px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
}

.card {
	/* position: relative; */
	-webkit-transition: 1.5s ease-in-out;
	transition: 1.5s ease-in-out;
	transform-style: preserve-3d;
    cursor: pointer;
}

.side {
	position: absolute;
	text-align: center;
	/* width: 100%; */
	/* height: 100%; */
	
	color: #fff;
	/* transform-style: preserve-3d; */
	backface-visibility: hidden;
	border-radius: 20px;
}
.content {
	/* transform: translatez(80px) scale(0.8);
	line-height: 1.5em; */


}
.content-background{
	/* transform: translatez(80px) scale(0.8);
	line-height: 1.5em; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}



.front {
	z-index: 2;
	background-size: 100vh;
	background-size: cover;
	/* background-image: url("../../../public/img/gradient.jpg"); */
    /* background-color: #868686; */
}
.back {
    /* background-color: #929292; */
	transform: rotateY(180deg);
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-image: url("../../../public/img/gradient.jpg"); */
}
.card:hover {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
