
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    background-color: rgb(243, 243, 243) !important;
}

/* .swiper-pagination{
    position: absolute !important;
    bottom: 1px !important;
} */