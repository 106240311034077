.sliderr {
    
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
}

/* .sliderr::before,
.sliderr::after {

  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
} */

/* .sliderr::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.sliderr::before {
  left: 0;
  top: 0;
} */

.sliderr .slide-track {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 50px;
  margin-right: 50px;
}

.sliderr .slide {
  width: 200px;
  height: 200px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .sliderr .slide img {
  width: 100%;
  height: auto;
} */


