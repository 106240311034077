

@media screen and (min-width: 600px) {
    .iconSize svg{
        width: 40px !important;
        height: 40px !important;
    }
  }
@media screen and (min-width: 900px) {
    .iconSize svg{
        width: 70px !important;
        height: 70px !important;
    }
  }

  