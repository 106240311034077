#slider-wrapper {
  height: 600px;
  margin: 0;
  display: grid;
  grid-template-rows: 500px 100px;
  grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
  align-items: center;
  justify-items: center;
}

#slider-wrapper div#carousel {
  grid-row: 1 / 2;
  grid-column: 1 / 8;
  width: 90vw;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 600px;
  --items: 5;
  --middle: 3;
  --position: 1;
  pointer-events: none;

}

#slider-wrapper div.item {
  position: absolute;
  --r: calc(var(--position) - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transition: all 0.25s linear;
  transform: rotateY(calc(-10deg * var(--r)))
    translateX(calc(-400px * var(--r)));
  z-index: calc((var(--position) - var(--abs)));
  background-color: #232D3F;
  
}

.item {
  opacity: 0.5; 
  transition: opacity 0.3s ease-in-out; 
  width: 250px;
  height: 350px;
  overflow: hidden;
}

.item.selected {
  opacity: 1.0; 
  color: white;
  width: 400px;
  height: 500px;
}

#slider-wrapper div.item:nth-of-type(1) {
  --offset: 1;
 
}
#slider-wrapper div.item:nth-of-type(2) {
  --offset: 2;

}
#slider-wrapper div.item:nth-of-type(3) {
  --offset: 3;

}
#slider-wrapper div.item:nth-of-type(4) {
  --offset: 4; 
  
}
#slider-wrapper div.item:nth-of-type(5) {
  --offset: 5; 
 
}
#slider-wrapper input{
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#slider-wrapper input:nth-of-type(1) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}
#slider-wrapper input:nth-of-type(1):checked ~ div#carousel {
  --position: 1;
}

#slider-wrapper input:nth-of-type(2) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}
#slider-wrapper input:nth-of-type(2):checked ~ div#carousel {
  --position: 2;
}

#slider-wrapper input:nth-of-type(3) {
  grid-column: 4 /5;
  grid-row: 2 / 3;
}
#slider-wrapper input:nth-of-type(3):checked ~ div#carousel {
  --position: 3;
}

#slider-wrapper input:nth-of-type(4) {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
}
#slider-wrapper input:nth-of-type(4):checked ~ div#carousel {
  --position: 4;
}

#slider-wrapper input:nth-of-type(5) {
  grid-column: 6 / 7;
  grid-row: 2 / 3;
}
#slider-wrapper input:nth-of-type(5):checked ~ div#carousel {
  --position: 5;
}

